//font-family
@use '@angular/material' as mat;
$font-family-base: "Work Sans";

//border-radius
// $border-radius: 0.3rem;
// $border-radius-lg: 0.6rem;
// $border-radius-sm: 0.3rem;

$min-contrast-ratio: 2;

//colores
$primary: #e77917;
$secondary: #bf6f32;
$success: #8db600;
$info: #0070b9;
$warning: #ffce1f;
$danger: #de3113;
$light: #FCEBDC;
$dark: #242424;
$text-gray: rgba($dark, 0.75);
$text-muted: #9e9e9e;


$btn-border-width: 2px;
$btn-font-weight: 500;
$btn-padding-y: 0.8rem;
$btn-padding-x: 1.3rem;
$btn-padding-y-sm: 0.5rem;
$btn-padding-x-sm: 0.9rem;
$btn-border-radius: 0.4rem;
$btn-border-radius-sm: 0.3rem;
$btn-border-radius-lg: 0.5rem;


// Espacios
$spacer: 1rem;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.75,
  ),
  3: (
    $spacer * 1.3,
  ),
  4: (
    $spacer * 2,
  ),
  5: (
    $spacer * 3,
  ),
  6: (
    $spacer * 5,
  ),
  7: (
    $spacer * 7,
  ),
  8: (
    $spacer * 10,
  ),
  9: (
    $spacer * 14,
  ),
  10: (
    $spacer * 20,
  ),
);

// Then import Bootstrap
@import "../node_modules/bootstrap/scss/bootstrap";
// Required Bootstrap imports
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

// Optional components
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/helpers";
@import "../node_modules/bootstrap/scss/utilities/api";

// Swiper Angular
@import "swiper/css";
@import "swiper/css/bundle";

// Fonts Google
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;400;500;600;700;800&display=swap");
//Custom theme Angular Material
@import "@angular/material/theming";
$custom-typography: mat.define-typography-config(
  $font-family: "Work Sans" !important,
);
@include mat.core($custom-typography);
body,
html {
  // color: $dark;
  font-size: 16px;
  font-weight: 400;
}

a {
  text-decoration: none;
}

// .btn-primary {
//   color: white;
// }

*:focus {
  outline: 0px !important;
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

.op-25 {
  opacity: 0.25;
}

.op-50 {
  opacity: 0.5;
}

.op-75 {
  opacity: 0.75;
}

.logoCanatur {
  width: 290px;
  @include media-breakpoint-only(xs) {
    width: 210px;
  }
}

.btn {
  border-radius: 0.3rem;
  // padding-left: 1.3rem;
  // padding-right: 1.3rem;
}

.modalFullScreen {
  max-width: none !important;
  width: 100vw !important;
  height: 100vh !important;
  @include media-breakpoint-only(xs) {
    width: 100vw !important;
    height: 100vh !important;
  }
}

// .mat-dialog-container {
//     border-radius: 0px !important;
// }
.modalConferencia {
  .mat-dialog-container {
    width: auto;
    height: 90vh;
    border-radius: 0.5rem;
    padding: 0px;
    @include media-breakpoint-only(xs) {
      width: 90vw;
      height: auto;
    }
  }
}

.sizeImageModalConferencia {
  height: 100%;
  width: auto;
  @include media-breakpoint-only(xs) {
    width: 100%;
    height: auto;
  }
}

.buttonClose {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
}

// .material-icons {
//     vertical-align: middle;
// }
// padding-bottom: 3px }
.linkMenuDesktop {
  font-size: 1rem;
  font-weight: 400;
  color: $dark;
  margin-right: 1rem;
  // text-decoration: none;
}

.linkMenuMobile {
  font-size: 1.1rem;
  font-weight: 400;
}

.linkMenuFooter {
  font-size: 1rem;
  font-weight: 400;
}

.bannerHome {
  height: 620px;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/candelaria.jpg?alt=media&token=6f177a9d-6ef9-4728-b392-49a0c72170c1");
  // background: linear-gradient(rgba($dark, 0.75) 100%, rgba($primary, 0.5)100%), url('assets/images/candelariaHome.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  @include media-breakpoint-only(xs) {
    height: 100%;
    // background-image: url('/assets/images/candelaria.jpg');
  }
}

.bannerHomeCopy {
  width: 50%;
  @include media-breakpoint-only(xs) {
    width: 100%;
  }
  .titulo {
    font-size: 2.3rem;
    line-height: 120%;
    font-weight: 300;
    @include media-breakpoint-only(xs) {
      font-size: 1.6rem;
    }
  }
}

.copyNosotrosHome {
  font-size: 2.5rem;
  line-height: 120%;
  font-weight: 700;
  text-transform: uppercase;
  @include media-breakpoint-only(xs) {
    font-size: 1.5rem;
    // text-align: center;
  }
}

.croppedImageAsociados {
  object-fit: cover;
  object-position: center;
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  @include media-breakpoint-only(xs) {
    height: 12rem;
    width: 12rem;
  }
}

.textAsociadosHome {
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 400;
}

.tituloAsociados {
  font-size: 3.8rem;
  font-weight: 700;
  line-height: 100%;
  @include media-breakpoint-only(xs) {
    font-size: 2.8rem;
  }
}

.btn-circle {
  width: 3.5rem;
  height: 3.5rem;
  // padding: 0.75rem 1rem !important;
  border-radius: 50%;
  // vertical-align: center !important;
}

.croppedImageExpoTurismo {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: auto;
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: 0px 8px 16px rgba($danger, 0.1);
  margin-bottom: 1.5rem;
  // @include media-breakpoint-only(xs) {
  //     width: 15rem;
  //     height: 15rem;
  // }
}

.grillaEmpresasAsociadas {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 4rem;
  row-gap: 3rem;
  @include media-breakpoint-only(xs) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 2rem;
  }
}

.imageEmpresaAsociada {
  width: 10rem;
  @include media-breakpoint-only(xs) {
    width: 5rem;
  }
}

.imageEmpresa {
  width: 10rem;
  @include media-breakpoint-only(xs) {
    width: 8rem;
  }
}

.grillaCamarasRegionales {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  @include media-breakpoint-only(xs) {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }
}

.imageGremio {
  width: 10rem;
  border-radius: 50%;
  height: auto;
  @include media-breakpoint-only(xs) {
    width: 6rem;
  }
}

.grillaGremios {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  @include media-breakpoint-only(xs) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.breadcrumb {
  background: none;
  padding: 0px;
}

.bgMision {
  background: linear-gradient(
      rgba($primary, 0.8) 100%,
      rgba($secondary, 0.9) 100%
    ),
    url("https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/nosotros%2FbgMision.jpg?alt=media&token=8a28469a-4287-4b40-94bd-d87df13b9457");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  @include media-breakpoint-only(xs) {
    background-size: cover;
  }
}

.bgVision {
  background: linear-gradient(
      rgba($danger, 0.8) 100%,
      rgba($secondary, 0.9) 100%
    ),
    url("https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/nosotros%2FbgVision.jpg?alt=media&token=b91b9fc9-e970-40fa-9097-0ef0c3a2f5de");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  @include media-breakpoint-only(xs) {
    background-size: cover;
  }
}

.grillaFunciones {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;
  @include media-breakpoint-only(xs) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.grillaAcciones {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  @include media-breakpoint-only(xs) {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }
}

.grillaJuntaDirectiva {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  @include media-breakpoint-only(xs) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.imageFuncion {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 0rem 1rem 1rem rgba($dark, 0.1);
  object-fit: cover;
  @include media-breakpoint-only(xs) {
    // width: 65px;
  }
}

.imageMiembro {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  object-fit: cover;
  background-color: $light;
  @include media-breakpoint-only(xs) {
    // width: 65px;
  }
}

.myButtonCircleRedSocial {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  // font-size: 0.8rem !important;
}

.backFormaAsociados {
  background-image: url("assets/images/backFormaAsociados.png");
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
}

.titleGroup {
  font-size: 2rem;
  color: $primary;
  font-weight: 500;
  margin-top: 3rem;
  @include media-breakpoint-only(xs) {
    font-size: 1.7rem;
    margin-top: 2rem;
  }
}

.bgMarketplace {
  background: linear-gradient(
      rgba($danger, 0.8) 100%,
      rgba($secondary, 0.9) 100%
    ),
    url("https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/backMarketplace.jpg?alt=media&token=e0e6b366-8061-47c2-965a-ad5b8f119593");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  @include media-breakpoint-only(xs) {
    background-size: cover;
  }
}

.bannerMarketplace {
  background: linear-gradient(rgba(white, 0.9) 0%, rgba(#fcefe3, 0.9) 100%),
    url("https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/seccionesHome%2Fmarketplace.jpg?alt=media&token=442a1ea5-f1a6-4b81-9d97-95d2dd79a2b3");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  backdrop-filter: blur(5px);
  // border: 3px solid $primary;
  @include media-breakpoint-only(xs) {
    background-size: cover;
  }
  .logoMarketplace {
    width: 180px;
    @include media-breakpoint-only(xs) {
      width: 160px;
    }
  }
  .logoYTQP {
    width: 75px;
    @include media-breakpoint-only(xs) {
      width: 75px;
    }
  }
}

.bannerHelvetas {
  background: linear-gradient(rgba(white, 0.8) 0%, rgba(#fcefe3, 0.8) 100%),
    url("https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/helvetas%2Fverde.jpg?alt=media&token=f449943a-4d9f-4199-9095-db9367d3c9fa");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  // backdrop-filter: blur(5px);
  .titulo {
    font-size: 1.8rem;
    line-height: 120%;
    font-weight: 600;
    border: 3px solid $success;
    border-radius: $border-radius-lg;
    padding: 0.6rem 1rem;
    margin-bottom: 1rem;
    display: inline-block;
    @include media-breakpoint-only(xs) {
      font-size: 1.6rem;
    }
  }
}

.backSafePlace {
  background: linear-gradient(
      rgba($white, 0.75) 100%,
      rgba($secondary, 0.9) 100%
    ),
    url("https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/backSafePlace.jpg?alt=media&token=9d987eb9-77bd-493c-a8e1-9f952485c1c4");
  background-size: cover;
  background-position: center center;
  .logoSafePlace {
    width: 180px;
    mix-blend-mode: multiply;
    @include media-breakpoint-only(xs) {
      width: 100px;
    }
  }
}

.grillaGremiosFooter {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 2rem;
  @include media-breakpoint-only(xs) {
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
}

table {
  width: 100% !important;
}

.imageProductAdmin {
  width: 5rem;
  height: 4rem;
  object-fit: cover;
  @include media-breakpoint-only(xs){
    width: 4rem;
    height: 3rem;
  }
}

.imageNoticiaAdminEdit {
  width: 100%;
  height: 320px;
  object-fit: cover;
  @include media-breakpoint-only(xs){
    // width: 5rem;
    height: 180px;
  }
}

mat-icon {
  vertical-align: middle;
}

.grillaNoticiasHome {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  @include media-breakpoint-only(xs) {
    grid-template-columns: repeat(1, 1fr);
  }
  @include media-breakpoint-only(sm) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.imageNoticiaHome {
  object-fit: cover;
  width: 100%;
  height: 180px;
  border-radius: 0.25rem;
}

.grillaSeccionesHome {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.2rem;
  @include media-breakpoint-only(xs) {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }
}

.bgSeccionHome {
  background: linear-gradient(rgba(white, 0.9) 0%, rgba(#fcefe3, 0.9) 100%),
    url("https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/seccionesHome%2Fmarketplace.jpg?alt=media&token=442a1ea5-f1a6-4b81-9d97-95d2dd79a2b3");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  backdrop-filter: blur(5px);
}

.bgGradientSeccion {
  background: linear-gradient(rgba($white, 0.75) 0%, rgba($white, 0.75) 100%);
  background-blend-mode: multiply;
  backdrop-filter: blur(5px);
}

.myButtonSeccion {
  border-top-left-radius: 1rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 0.85rem;
}

// .swiper-container {
//     border-radius: 0.25rem;
//     @include media-breakpoint-only(xl) {
//         height: 100%;
//     }
//     @include media-breakpoint-only(lg) {
//         height: 100%;
//     }
//     @include media-breakpoint-only(md) {
//         height: 100%;
//     }
//     @include media-breakpoint-only(sm) {
//         height: 340px;
//     }
//     @include media-breakpoint-only(xs) {
//         height: 400px;
//     }
// }
.myBannerNoticias {
  height: 100%;
  @include media-breakpoint-only(md) {
    height: 100%;
  }
  @include media-breakpoint-only(sm) {
    height: 340px;
  }
  @include media-breakpoint-only(xs) {
    height: 240px;
  }
}

.myBannerMarketplace {
  height: 100%;
  // @include media-breakpoint-only(xs) {
  //     height: 120px;
  // }
  .titulo {
    font-size: 1.5rem;
    line-height: 120%;
    font-weight: 600;
    @include media-breakpoint-only(xs) {
      font-size: 1.3rem;
    }
  }
}

.imagesBanner {
  background-size: cover;
  background-position: center center;
}

.swiper-pagination-bullet {
  opacity: 1 !important;
  background-color: rgba($dark, 0) !important;
  border: 1px solid $primary;
  width: 12px !important;
  height: 12px !important;
  margin: 0 6px !important;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background-color: $primary !important;
}

.swiper-button-next,
.swiper-button-prev,
.swiper-container-rtl .swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  color: $primary !important;
}

.bgGradient {
  background: linear-gradient(transparent 20%, $dark 100%);
  background-blend-mode: multiply;
}

.myShadowText {
  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.5);
}

.grillaAlianzas {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
  @include media-breakpoint-only(xs) {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }
}

.grillaSeccionesAdmin {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  @include media-breakpoint-only(xs) {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }
}

.myColorSpinner {
  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: white;
  }
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-left: 0px !important;
}

th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
  padding-right: 0px !important;
}

.hideColumnXs {
  display: flexbox;
  @include media-breakpoint-only(xs) {
    display: none;
  }
}

.mat-cell {
  padding: 0.5rem 1rem 0.5rem 0 !important;
}

.bgHelvetas {
	background-image: url('https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/helvetas%2Fverde.jpg?alt=media&token=f449943a-4d9f-4199-9095-db9367d3c9fa');
	background-position: center center;
	background-size: cover;
}

.grillaLogosHelvetas {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 3rem;
	@include media-breakpoint-only(xs) {
		gap: 1rem
	}
}

.grillaLogosHelvetasHome {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 1rem;
	@include media-breakpoint-only(xs) {
		gap: 1rem
	}
}

.buttonSubmit {
  width: 220px;
  @include media-breakpoint-only(xs) {
    width: 100%;
  }
}

.myColorSpinnerWhite {
  mat-spinner {
    stroke: white !important;
  }
}

.btn-primary {
  .mat-spinner circle {
    stroke: $dark;
  }
}

.mat-dialog-container {
  position: relative;
}

.dialogSmall {
  .mat-dialog-container {
    max-width: 380px;
  }
}

.dialogDeleteItem {
  width: 420px !important;
  @include media-breakpoint-only(xs) {
    width: initial !important;
  }
}

// .mat-icon-button .mat-button-wrapper {
//   line-height: 100% !important;
//   display: flex !important;
//   justify-content: center !important;
// }

.mat-icon-button .mat-button-wrapper {
  line-height: 100% !important;
  display: flex !important;
  justify-content: center !important;
}

.mdc-icon-button {
  font-size: inherit !important;
}

.buttonSubmit {
  width: 220px;
  @include media-breakpoint-only(xs) {
    width: 100%;
  }
}

.extraSmall {
  font-size: 0.75rem;
}

.contenedorImage {
  display: grid;
}

.image, .loader {
  grid-area: 1 / 1;
}

.mySpinnerDark {
  .mat-spinner circle {
    stroke: $dark;
  }
}

.mySpinnerWhite {
  .mat-spinner circle {
    stroke: $white;
  }
}

.imageItemAdmin {
  width: 59.5px;
  height: 59.5px;
  object-fit: cover;
}


.sliderPtm {
  width: 100%;
  // object-fit: cover;
  background-size: cover;
  @include media-breakpoint-only(xxl) {
    height: 620px;
  }
  @include media-breakpoint-only(xl) {
    height: 520px;
  }
  @include media-breakpoint-only(lg) {
    height: 420px;
  }
  @include media-breakpoint-only(md) {
    height: 360px;
  }
  @include media-breakpoint-only(xs) {
    height: 220px;
  }
}

.slideCnt {
  width: 100%;
  height: 520px;
  object-fit: cover;
  @include media-breakpoint-only(xs) {
    height: 220px;
  }
}

.grillaEmpresas {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  @include media-breakpoint-only(xs) {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }
}


// Custom Acordion

.mat-expansion-panel {
  border-radius: 0 !important;
  background: none !important;
}

.mat-expansion-panel-body {
  padding: 0 0 16px 0 !important;
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: none;
  border-bottom: 1px solid rgba($dark, 0.3);
  color: inherit;
  border-radius: 0rem !important;
}

.mat-expansion-panel-header {
  padding: 2rem 1.2rem 2rem 0rem  !important;
  background: none !important;
}

.mat-expansion-panel-header-title {
  display: inline !important;
  align-items: center;
  margin-right: 5rem !important;
  @include media-breakpoint-only(xs) {
    margin-right: 0.25rem !important;
  }
}

.mat-expansion-panel-header-description {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  @include media-breakpoint-only(xs) {
    display: inline !important;
  }
}

.mat-accordion .mat-expansion-panel:first-of-type {
  border-top-right-radius: 0rem !important;
  border-top-left-radius: 0rem !important;
}

.mat-accordion .mat-expansion-panel:last-of-type {
  border-bottom-right-radius: 0rem !important;
  border-bottom-left-radius: 0rem !important;
}

.mat-expansion-panel-spacing {
  margin: 0 !important;
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: $dark;
}

.imageNoticiaAdmin {
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.imageDetalleNoticiaHel {
  height: 380px;
  width: 100%;
  object-fit: cover;
  @include media-breakpoint-only(xs) {
    height: 240px;
  }
}

.bodyNoticiaHel {
  img {
    width: 100%;
    height: auto;
  }
}

.imageNoticiaHelCard {
  width: 200px;
  height: 190px;
  object-fit: cover;
  @include media-breakpoint-only(xs) {
    width: 100px;
    height: 100%;
  }
}

.contentNoticiaHel {
  .titulo {
    font-weight: 500;
    line-height: 120%;
    font-size: 1.2rem;
    margin-bottom: 0.65rem;
    @include media-breakpoint-only(xs) {
      font-size: 0.95rem;
    }
  }
  .subtitulo {
    line-height: 130%;
    font-size: 0.9rem;
    color: $text-gray;
    margin-bottom: 0.5rem;
    @include media-breakpoint-only(xs) {
      font-size: 0.75rem;
    }
  }
}

.videoMiniaturaAdmin {
  width: 200px;
  @include media-breakpoint-only(xs) {
    width: 120px;
  }
}

.bgBosque {
  background-image: url('https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/helvetas%2FbosqueBaja.jpg?alt=media&token=a8c5b63d-d5b6-47e8-8d5d-3f95b7f19d70');
}

.blur {
  backdrop-filter: blur(10px);
}

.imageNoticiaCardHel {
  width: 100%;
  height: 260px;
  object-fit: cover;
  @include media-breakpoint-only(xs) {
    height: 220px;
  }
}

.bgBosqueHel {
  background: linear-gradient(rgba($success, 0.8) 100%, rgba($success, 0.9)100%), url('https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/helvetas%2FbosqueBaja.jpg?alt=media&token=a8c5b63d-d5b6-47e8-8d5d-3f95b7f19d70');
}

.blurBosqueHel {
  backdrop-filter: blur(9px);
}

.marginNegative {
  margin-top: -12rem;
  @include media-breakpoint-only(xs) {
    margin-top: -6rem;
  }
}

.buttonDotted {
  width: 90px;
  border: dashed 2px rgba($primary, 0.5);
  background-color: rgba($primary, 0);
  aspect-ratio: 1 / 1;
}

.buttonDotted:hover {
  background-color: rgba($primary, 0.2);
}

.imageItemPreview {
  width: 72px;
  height: 72px;
  aspect-ratio: 1 / 1;
  object-fit: contain;
}

.grillaImagesItems {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
  @include media-breakpoint-only(xs) {
    grid-template-columns: repeat(4, 1fr);
    gap: 0.25rem;
  }
}

.buttonDeleteImageItem {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.btn-sm {
  i-tabler {
    width: 20px;
    height: 20px;
  }
}

.btn-circle-xs {
  width: 34px;
  height: 34px;
  padding: 0;
  border-radius: 50%;
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  i-tabler {
    display: flex;
    width: 20px;
    height: 20px;
    stroke-width: 3;
  }
}

.imageItemFile {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

// Landing XVI CNT

.bgBannerXVIcnt {

  // background: url('https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FondasOutlineCanatur.png?alt=media&token=c1d47e66-d2c1-4a79-a6cb-63b130325c67'), lightgray -677.904px -930.964px / 100.549% 143.436% no-repeat, linear-gradient(0deg, rgba(60, 124, 199, 0.20) 0%, rgba(60, 124, 199, 0.20) 100%), linear-gradient(0deg, #242424 0.02%, rgba(36, 36, 36, 0.00) 72.18%), radial-gradient(82.58% 61.41% at 50% 25.11%, rgba(191, 111, 50, 0.22) 0%, rgba(131, 68, 42, 0.75) 100%), url('https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FmujerCampo.jpg?alt=media&token=3c44be7b-6871-462c-bd0d-ccf2aca6a4ad'), lightgray 50% / cover no-repeat;
  // background-blend-mode: screen, hard-light, normal, normal, normal;

  background: url('https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FondasOutlineCanatur.png?alt=media&token=c1d47e66-d2c1-4a79-a6cb-63b130325c67') no-repeat, linear-gradient(0deg, rgba(60, 124, 199, 0.20) 0%, rgba(60, 124, 199, 0.20) 100%), linear-gradient(0deg, #242424 0.02%, rgba(36, 36, 36, 0.00) 72.18%), radial-gradient(82.58% 61.41% at 50% 25.11%, rgba(191, 111, 50, 0.22) 0%, rgba(131, 68, 42, 0.75) 100%), url('https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FmujerCampo.jpg?alt=media&token=3c44be7b-6871-462c-bd0d-ccf2aca6a4ad') no-repeat;
  background-size:  auto, 100%, 100%, 100%, cover;
  background-blend-mode: screen, hard-light, normal, multiply, normal;
  background-position: left top, center, center, center, center ;

  // background: linear-gradient(rgba($success, 0.8) 100%, rgba($success, 0.9)100%), url('https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FmujerCampo.jpg?alt=media&token=3c44be7b-6871-462c-bd0d-ccf2aca6a4ad');

}

.logoHead {
  width: 260px;
  @include media-breakpoint-only(xs) {
    width: 150px;
  }
}

// .backDescription {
//   background: url('https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2Fhorizonte.jpg?alt=media&token=61acaadb-8e0d-4f2a-9bff-7a0eb0bffef0'), rgba($secondary, 1);
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-blend-mode: luminosity;
// }


// Back Description

.backDescription {
  position: relative;
  background-color: rgba($success, 1);
}

.backDescription:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2Fhorizonte.jpg?alt=media&token=61acaadb-8e0d-4f2a-9bff-7a0eb0bffef0');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  mix-blend-mode: luminosity;
  filter: blur(8px);
}



.backEjesTematicos {
  position: relative;
  background-color: rgba($dark, 1);
}

.backEjesTematicos:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FbackGradient4.jpeg?alt=media&token=1e765690-6e5e-4389-8519-58d96a030ef9');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  mix-blend-mode: luminosity;
  filter: blur(8px);
}


// Back Programa

.backPrograma {
  position: relative;
  background-color: rgba($success, 1);
}

.backPrograma:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2Fcolors.png?alt=media&token=fb6dc271-cb83-42f2-8825-e15f1e12f657');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  mix-blend-mode: luminosity;
  filter: blur(8px);
}



// .backPrograma {
//   background: url('https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2Fcolors.png?alt=media&token=fb6dc271-cb83-42f2-8825-e15f1e12f657'), rgba($primary, 1);
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-blend-mode: luminosity;
//   // opacity: 0.3;
// }

.backAuspiciadores {
  background: url('https://firebasestorage.googleapis.com/v0/b/canatur-1afd6.appspot.com/o/xvicnt%2FbackGradient4.jpeg?alt=media&token=1e765690-6e5e-4389-8519-58d96a030ef9'), rgba($dark, 1);
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: luminosity;
  // opacity: 0.3;
}

.backBlur {
  background: rgba($primary, 0.75);
  backdrop-filter: blur(8px);
}

.backBlur2 {
  // background: rgba($primary, 0.75);
  backdrop-filter: blur(8px);
}

.backBlurDark {
  background: rgba($dark, 0.75);
  backdrop-filter: blur(8px);
}